import React from "react";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import useSiteMetadata from "../components/SiteMetadata";
import {
  sizes,
  colorAliases,
  colors,
  fontSizes,
  borderRadius,
  boxShadow,
  MOBILEBREAKPOINT,
  appPalette,
} from "../style/design-system";
import Layout from "../components/Layout";
import OuterContainer from "../components/OuterContainer";
import { CtaButton } from "../components/CtaButton";

const StyledCtaButton = styled(CtaButton)`
  padding: ${sizes.md} ${sizes.xl};
  margin-bottom: ${sizes.xl};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.sm} ${sizes.lg};
  }
`;

const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes["2xl"]} ${sizes.lg} ${sizes.lg} ${sizes.lg};
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.lg};
  }
`;

const SectionHeader = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["3xl"]};
  margin-bottom: ${sizes.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["2xl"]};
    margin: 0 0 ${sizes.xs} 0;
  }
`;

const SmallHeader = styled.h4`
  font-weight: 700;
  font-size: ${fontSizes["2xl"]};
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["xl"]};
  }
`;

const ContactLink = styled.span`
  display: inline-block;
  line-height: 1;
  border-bottom: 2px solid ${colors.gray["200"]};
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid ${colorAliases.textColor};
  }
`;

const SectionTagline = styled.h4`
  margin: 0 auto ${sizes["2xl"]} auto;
  font-weight: 300;
  font-size: ${fontSizes.lg};
  line-height: 1.5;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 auto ${sizes.xl} auto;
  }
`;

const FullBleedGrid = styled(OuterContainer)`
  display: grid;
  grid-template-columns:
    minmax(${sizes.lg}, auto) minmax(150px, 400px) minmax(150px, 400px) minmax(
      150px,
      400px
    )
    minmax(${sizes.lg}, auto);
  grid-template-rows: 48px 24px 250px 150px 24px 48px;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    grid-template-columns: ${sizes.lg} ${sizes.lg} minmax(200px, 90vw) ${sizes.lg} ${sizes.lg};
    grid-template-rows: ${sizes.lg} auto auto auto ${sizes.lg};
    margin-bottom: 0;
  }
`;

const BeigeBackground = styled.div`
  background: ${colorAliases.beige};
  grid-column: 1 / -1;
  grid-row: 1 / 4;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    grid-row: 1 / -1;
  }
`;

const Plan1 = styled.div`
  background: white;
  border-radius: ${borderRadius.md};
  padding: ${sizes.lg};
  grid-row: 2 / 6;
  grid-column: 3 / 4;
  box-shadow: ${boxShadow.lg};
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    border: 1px solid ${colorAliases.beige};
    grid-column: 2 / 5;
    grid-row: 3 / 4;
  }
`;
const Plan2 = styled(Plan1)`
  grid-row: 3 / 5;
  grid-column: 4 / 5;
  border-radius: 0 ${borderRadius.md} ${borderRadius.md} 0;
  box-shadow: ${boxShadow.md};
  z-index: 1;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    background: white;
    border: none;
    border-radius: 0 0 ${borderRadius.md} ${borderRadius.md};
    grid-column: 3 / 4;
    grid-row: 4 / 5;
  }
`;

const Plan0 = styled(Plan2)`
  grid-column: 2 / 3;
  grid-row: 3 / 5;
  border-radius: ${borderRadius.md} 0 0 ${borderRadius.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    border-radius: ${borderRadius.md} ${borderRadius.md} 0 0;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
`;

const PlanHeader = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["4xl"]};
  margin: 0;
  text-align: center;
  color: ${appPalette.darkTeal};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes.xl};
  }
`;

const Price = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["5xl"]};
  margin: ${(props) => props.margin};
  text-align: center;
  margin: 0;
  color: ${appPalette.teal};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes.xl};
  }
`;

const Content = styled.div`
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: ${sizes.md} 0;
  }
`;
const Credits = styled.h4`
  font-weight: 700;
  font-size: ${fontSizes["7xl"]};
  color: ${colorAliases.textColor};
  margin: 0;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["5xl"]};
  }
`;
const CreditsExample = styled.p`
  margin: 0;
  font-size: ${fontSizes.sm};
`;

const CreditValuationBox = styled.div`
  margin: 0 auto;
  max-width: 600px;
  margin-bottom: ${sizes["2xl"]};
  color: ${colors.gray[600]};
  font-size: ${fontSizes.sm};
  border-radius: ${borderRadius.md};
  /* background: ${colorAliases.beige}; */
  padding: ${sizes.md} ${sizes["xl"]};
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-bottom: 0;
    padding: ${sizes.md} ${sizes["3xl"]} ${sizes["2xl"]} ${sizes["3xl"]};
    width: 100%;
    border-radius: 0;
  }
`;
const CreditValuationHeader = styled.h4`
  margin: ${sizes["xs"]} 0;
`;
const CreditValuationLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-left: ${sizes.md};
`;
const CreditValuationText = styled.p`
  margin: 0;
`;
const CreditValuationValue = styled.span``;

export const PricingPageTemplate = ({
  location,
  title,
  subtitle,
  subtitletwo,
  pricing,
  creditvaluation,
  metaTitle,
}) => {
  const { title: siteTitle, siteUrl } = useSiteMetadata();
  const small = pricing.packages[0];
  const medium = pricing.packages[1];
  const large = pricing.packages[2];

  return (
    <div>
      <Helmet>
        <title>{`${metaTitle} | ${siteTitle}`}</title>
        <link rel="canonical" href={siteUrl + location.pathname} />
      </Helmet>
      <OuterContainer>
        <SectionInnerContainer>
          <SectionHeader>{title}</SectionHeader>
          <SectionTagline style={{ marginBottom: sizes.md }}>
            {subtitle}
          </SectionTagline>
          <StyledCtaButton>Bestill signaturpakke</StyledCtaButton>
        </SectionInnerContainer>
      </OuterContainer>

      <FullBleedGrid>
        <BeigeBackground></BeigeBackground>
        <Plan0>
          <PlanHeader>{small.name}</PlanHeader>
          <Content>
            <Credits>{small.credits}</Credits>
            <CreditsExample>25 kr per signatur*</CreditsExample>
          </Content>
          <Price>{small.price}</Price>
        </Plan0>
        <Plan1>
          <PlanHeader>{medium.name}</PlanHeader>
          <Content>
            <Credits>{medium.credits}</Credits>
            <CreditsExample>20 kr per signatur*</CreditsExample>
          </Content>
          <Price>{medium.price}</Price>
        </Plan1>
        <Plan2>
          <PlanHeader>{large.name}</PlanHeader>
          <Content>
            <Credits>{large.credits}</Credits>
            <CreditsExample>18 kr per signatur*</CreditsExample>
          </Content>
          <Price>{large.price}</Price>
        </Plan2>
      </FullBleedGrid>
      <OuterContainer>
        <SectionInnerContainer style={{ paddingTop: 0 }}>
          <SmallHeader>
            Større behov?{" "}
            <ContactLink id="contact-link">Kontakt oss i chatten</ContactLink>
          </SmallHeader>
          <p>
            *1 kreditt gir 1 BankID signatur eller 2 InkSign signaturer eller 10
            SMS varslinger.
          </p>
          <p>
            Rollover: Signaturpakker har 12 måneders varighet. Dersom man kjøper
            en ny signaturpakke før 12 måneder har gått vil eventuell restsaldo
            overføres til 12 nye måneder.
          </p>
        </SectionInnerContainer>
      </OuterContainer>
    </div>
  );
};

const PricingPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout language={frontmatter.language}>
      <PricingPageTemplate
        location={location}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        subtitletwo={frontmatter.subtitletwo}
        pricing={frontmatter.pricing}
        creditvaluation={frontmatter.creditvaluation}
        metaTitle={frontmatter.metaTitle}
      />
    </Layout>
  );
};

export default PricingPage;

export const pageQuery = graphql`
  query PricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        metaTitle
        language
        title
        subtitle
        subtitletwo
        pricing {
          packages {
            name
            price
            credits
          }
        }
        creditvaluation {
          types {
            type
            credits
          }
        }
      }
    }
  }
`;
